import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      Software engineer, currently building{" "}
      <a href="https://www.nabla.com">Nabla</a>.
    </p>
    <p>Some things I've helped building over the past few years:</p>
    <ul>
      <li>
        <a href="https://www.wit.ai">Wit.ai</a>, a natural language processing
        API for developers
      </li>
      <li>
        <a href="https://pytorch.org">PyTorch</a>, a machine learning framework
      </li>
      <li>
        <a href="https://www.messenger.com">Messenger</a>, a messaging app and
        platform developed by Facebook
      </li>
      <li>
        <a href="https://medium.com/@kifi">Kifi</a>, a knowledge sharing app
      </li>
    </ul>
  </Layout>
)

export default IndexPage
